<template>
    <div>
        <div class="info_top">
            <a-input-search v-model:value="value" placeholder="输入通告时间进行查询" style="width: 200px"
            @keyup="getSearchData" @search="onSearch"/>
             <a-button type="primary" class="delete" @click="getMessageData()" :size="size" shape="round">
             <ReloadOutlined />刷新</a-button>
            <span>  消息通告，读取后删除即可</span>
        </div>
        <a-table 
          :columns="columns"
          :data-source="data"
          :loading="loading"
          :pagination='pagination'
          @change="changeMessageData"
          class="ant-table-striped"
          :rowClassName="isLeader">
          <template #action="{text}">
            <a-button type="danger" class="delete" @click="deleteMessage(text)">删除</a-button>
          </template>
        </a-table>
    </div>
</template>

<script lang="ts">
import { message } from "ant-design-vue";
import { defineComponent } from "vue";
import { ReloadOutlined } from "@ant-design/icons-vue";

const columns = [
    {
        title: "被通告人",
        dataIndex: "recipientName",
        key: "recipientName",
        align: "center",
    },
    {
        title: "通告任务",
        dataIndex: "missionName",
        key: "missionName",
        align: "center",
    },
    {
        title: "通告时间",
        dataIndex: "time",
        key: "time",
        align: "center",
    },
    {
        title: "操作",
        slots: { customRender: "action" },
        key: "action",
        align: "center",
    },
];

const data:any[] = [];
export default defineComponent({
    name: "messageinfo",
    components: {
       ReloadOutlined,
    },
    data() {
        return {
            columns: columns,//表格的列的显示
            data: data,//表格数据
            value: '',//用于搜索
            loading: false,//是否加载
            pagination: {//分页相关
                current: 1,//当前在第几页
                pageSize: 7,//每页有多少张
                total: 10//总共有多少条数据
            },
            timer: 0,//搜索的计时器
        };
    },
    methods: {
        //领导发的信息加红
        isLeader(record:any) {
          console.log(record.senderName);
          
          if (record.senderName == '领导') {
            let a = "background-leader"
            return a;
          }
        },
        //获得消息接口
        getMessageData() {
            this.loading = true;
            let url = 'http://39.100.158.75:8080/message/getMessageByKeyWord';
            this.$axios({
                url: url,
                method: 'GET',
                headers: {
                    token: this.$storage.get('userinfo').data.token
                },
                params: {
                    page: this.pagination.current,
                    limit: this.pagination.pageSize,
                    keyWord: this.value
                }
            }).then((res:any) => {              
                console.log(res);
                if (res.data.data == null) {
                    message.info(res.data.message);
                    this.data = [];
                    this.pagination.total = 0;
                    this.loading = false; 
                } else {
                    if (res.data.success == true) {
                        message.info(res.data.message);
                        this.data = res.data.data.list;
                        this.pagination.total = res.data.data.totalPageCount;
                    } else {
                        message.info(res.data.message);
                    }
                    this.loading = false;                    
                }
                // this.value = '';
            });
        },

        //删除消息接口
        deleteMessage(data:any) {
            // console.log(data);
            let url = 'http://39.100.158.75:8080/message/deleteOneMessage';
            this.$axios({
                url: url,
                method: 'POST',
                headers: {
                    token: this.$storage.get('userinfo').data.token
                },
                params: {
                    messageId: data.id
                }
            }).then((res) => {
                console.log(res);
                if (res.data.success == true) {
                    message.info(res.data.message);
                } else {
                    message.info(res.data.message);
                }
                let pagNum = this.pagination.total / this.pagination.pageSize;
                 if((this.pagination.total-1) % this.pagination.pageSize === 0 && (this.pagination.current) > pagNum){                     
                    this.pagination.current =
                    this.pagination.current - 1 > 0 ? this.pagination.current - 1 : 1;
                }
                this.getMessageData();
            });
        },

        //这个是在输入检索信息的过程中就自动开始查找了
        getSearchData() {
            if (this.value != '') {
                clearTimeout(this.timer);
                this.timer = setTimeout( () => {
                    this.pagination.current = 1
                    this.getMessageData();
                }, 600)
            }
        },

        //点击搜索查找
        onSearch() {
            this.pagination.current = 1
            this.getMessageData();
        },

        //页数更换
        changeMessageData(event:any) {
            this.pagination.current = event.current;
            this.getMessageData();
        }
    },
    mounted() {
    if(this.$storage.get('userinfo').data.role === 0) {
      this.columns = this.columns.filter((item) => item.dataIndex !== 'senderName')
    } else {
      this.columns = this.columns.filter((item) => item.dataIndex !== 'recipientName')
    }
    this.getMessageData();
        message.warning('本页面建议横屏使用！', 4);
    }
});
</script>

<style lang="scss" scoped>
.ant-table-striped :deep(.table-striped) {
  background-color: #fafafa;
}  // 设置斑马纹的样式
.info_top {
  padding: 10px 5px;
  margin-top: -14px;
}
:deep() .background-leader {
  background: rgba(230,162,60,0.8);
  font-size: 16px;
  font-weight: 600;
}
</style>